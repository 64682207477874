import React from 'react';
import { RfhTheme } from '@rfh/ui';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { FustDetail, FustList } from './modules';

const App = () => {
  return (
    <>
      <Suspense fallback="loading">
        <RfhTheme>
          <Routes>
            <Route index element={<FustList />} />
            <Route path="fustlijst" element={<FustList />} />
            <Route path='index.html' element={<FustList />} />
            <Route path='fustlijst/:lang' element={<FustList />} />
            <Route path=':fustId/:lang' element={<FustDetail />} />
          </Routes>
        </RfhTheme>
      </Suspense>
    </>
  );
}

export default App;