import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './common/i18n/en.json';
import de from './common/i18n/de.json';
import nl from './common/i18n/nl.json';

const resources = {
  en,
  de,
  nl
}

export const defaultLanguage = 'nl'

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: 'translation',
    fallbackLng: defaultLanguage
  });

export default i18n;