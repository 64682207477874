import React from 'react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { init } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { SentryConfig } from './common/config/sentry.config';

init({
  environment: SentryConfig.sentryEnvironment,
  dsn: SentryConfig.sentryScope,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: SentryConfig.sentryRate,
})

ReactDOM.render(
  <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
