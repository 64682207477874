import axios, { AxiosError } from 'axios';
import { ExportToCsv } from 'export-to-csv';
import XLSX from "sheetjs-style";

import { ProductSelectionEnum } from './../enums';
import { FustExportModel } from './../models';
import { fustExcelExportEndpoint } from './api-endpoints';

interface IFustExportService {
    get(taalCode: string, token: string): Promise<FustExportModel[]>;
    exportCsv(fustList: FustExportModel[]): void;
    exportToXlxs(fustList: FustExportModel[], headers: string[]): void;
    autofitColumns(json: any[], worksheet: XLSX.WorkSheet, header?: string[]): void;
}

export class FustExportService implements IFustExportService {
    async get(taalCode: string, token: string): Promise<FustExportModel[]> {
        try {
            const response = await axios.get<FustExportModel[]>(`${fustExcelExportEndpoint}/${taalCode}/${token}`);
            return response.data;
        } catch (ex) {
            const error = ex as AxiosError;
            console.log(error.message);
            throw error;
        }
    };
    exportCsv(fustList: FustExportModel[]) {
        const csvOptions = {
            fieldSeparator: ';',
            filename: 'Fustlijst',
            quoteStrings: '"',
            decimalSeparator: ',',
            showLabels: true,
            showTitle: true,
            title: 'sep=;',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true
        };

        const csvExporter = new ExportToCsv(csvOptions);
        csvExporter.generateCsv(fustList);
    };
    exportToXlxs(fustList: FustExportModel[], headers: string[]) {
        const fileName = "FustLijst";
        const fileExtension = ".xlsx";
        const headerStyle = {
            font: {
                sz: 12,
                bold: true,
                color: { rgb: "000000" }
            },
            alignment: {
                vertical: "center",
                horizontal: "left"
            }
        }

        let editedList: FustExportModel[] = [];
        fustList.forEach(x => {
            x.Statiegeld = "€" + x.Statiegeld;
            x.HuurUitgifte = "€" + x.HuurUitgifte;
            x.HuurRetour = "€" + x.HuurRetour;
            x.PrijsUitgifte = "€" + x.PrijsUitgifte;
            x.HuurAanvoerder = "€" + x.HuurAanvoerder;
            x.HuurKoper = "€" + x.HuurKoper;
            x.VerrekenprijsAanvoer = "€" + x.VerrekenprijsAanvoer;
            x.VerrekenprijsKoper = "€" + x.VerrekenprijsKoper;
            x.KickBackKorting = "€" + x.KickBackKorting;
            x.Product = ProductSelectionEnum[x.Product]
            editedList.push(x);
        });

        const ws = XLSX.utils.json_to_sheet(editedList);
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        const wb = {
            SheetNames: ["data"],
            Sheets: {
                data: ws
            }
        }
        ws['!rows'] = [
            { 'hpt': 27 },
        ]
        ws["A1"].s = headerStyle;
        ws["B1"].s = headerStyle;
        ws["C1"].s = headerStyle;
        ws["D1"].s = headerStyle;
        ws["E1"].s = headerStyle;
        ws["F1"].s = headerStyle;
        ws["G1"].s = headerStyle;
        ws["H1"].s = headerStyle;
        ws["I1"].s = headerStyle;
        ws["J1"].s = headerStyle;
        ws["K1"].s = headerStyle;
        ws["L1"].s = headerStyle;
        ws["M1"].s = headerStyle;
        ws["N1"].s = headerStyle;
        ws["O1"].s = headerStyle;
        ws["P1"].s = headerStyle;
        ws["Q1"].s = headerStyle;
        ws["R1"].s = headerStyle;
        ws["S1"].s = headerStyle;
        ws["T1"].s = headerStyle;
        ws["U1"].s = headerStyle;
        ws["V1"].s = headerStyle;
        ws["W1"].s = headerStyle;
        ws["X1"].s = headerStyle;
        ws["Y1"].s = headerStyle;
        ws["Z1"].s = headerStyle;

        this.autofitColumns(fustList, ws)
        XLSX.writeFile(wb, fileName + fileExtension);
    };
    autofitColumns(json: any[], worksheet: XLSX.WorkSheet, header?: string[]) {
        const jsonKeys = header ? header : Object.keys(json[0]);

        let objectMaxLength: any = [];
        for (let i = 0; i < json.length; i++) {
            let value = json[i];
            for (let j = 0; j < jsonKeys.length; j++) {
                if (typeof value[jsonKeys[j]] == "number") {
                    objectMaxLength[j] = 10;
                } else {

                    const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;

                    objectMaxLength[j] =
                        objectMaxLength[j] >= l
                            ? objectMaxLength[j]
                            : l;
                }
            }

            let key = jsonKeys;
            for (let j = 0; j < key.length; j++) {
                objectMaxLength[j] =
                    objectMaxLength[j] >= key[j].length
                        ? objectMaxLength[j]
                        : key[j].length;
            }
        }
        const wscols = objectMaxLength.map(w => { return { width: w } });
        worksheet["!cols"] = wscols;
    };
}   