import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
    reCaptchaRef: any
}

export const ReCAPTCHAComponent = (props: Props) => {
    return (
        <ReCAPTCHA
            ref={props.reCaptchaRef}
            size="invisible"
            sitekey='6Levz-woAAAAAKNDXVZMrxXcjgQdB0s_Mjbp9bia'
        />
    )
}