import { SelectItemProps } from '@rfh/ui/components/Inputs/RfhSelect';
import axios, { AxiosError } from 'axios';

import { FustDetailModel, FustReportModel, FustTypeModel } from './../models';
import { fustDetailEndpoint, fustListEndpiont, fustTypesEndpoint } from './api-endpoints';

export interface IFustService {
    get(code: string, token: string): Promise<FustDetailModel>;
    getAll(token: string): Promise<FustReportModel[]>;
    getTypes(token: string): Promise<SelectItemProps[]>;
}

export class FustService implements IFustService {
    async get(code: string, token: string): Promise<FustDetailModel> {
        try {
            const response = await axios.get<FustDetailModel>(`${fustDetailEndpoint}/${code}/nolang/${token}`);
            return response.data;
        } catch (ex) {
            const error = ex as AxiosError;
            console.log(error.message);
            throw error;
        }
    }
    async getAll(token: string): Promise<FustReportModel[]> {
        try {
            const response = await axios.get<FustReportModel[]>(`${fustListEndpiont}/${token}`);
            return response.data;
        } catch (ex) {
            const error = ex as AxiosError;
            console.log(error.message);
            throw error;
        }
    }
    async getTypes(token: string): Promise<SelectItemProps[]> {
        try {
            const response = await axios.get<FustTypeModel[]>(`${fustTypesEndpoint}/${token}`);
            return response.data.map<SelectItemProps>((item: FustTypeModel) => {
                return {
                    id: item.Code,
                    label: item.Omschrijving,
                    value: item.Code
                } as SelectItemProps
            });
        } catch (ex) {
            const error = ex as AxiosError;
            console.log(error.message);
            throw error;
        }
    }
}   