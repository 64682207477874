import React, { useRef } from 'react';
import { EmptyState, EmptyStateIcon, FallbackVisual, RfhTheme, RfhTypography } from '@rfh/ui';
import LoadingIconText from '@rfh/ui/components/LoadingStates/LoadingIconText';
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { defaultLanguage } from './../../i18n';
import {
    FustDetailModel, FustService,
    LanguageService,
    tableDataLabelVariant, tableDataVariant,
    tableHeaderVariant
} from './../../common';
import styles from './fustdetail.module.css';
import { ReCAPTCHAComponent } from '../reCAPTCHA';

export const FustDetail = () => {
    const reCaptchaRef = useRef<any>();
    let { t, i18n } = useTranslation();
    let navigate = useNavigate();
    let params = useParams();

    //Algemene states
    const [fustdetail, setFustdetail] = useState<FustDetailModel>();
    const [error, setError] = useState(false);

    //Deze UseEffect wordt gebruikt om de data op te halen en in te laden. 
    //Voor de rest heeft die geen dependency op een State waardoor dit alleen OnLoad uitgevoerd wordt.
    useEffect(() => {
        document.getElementById('topofpage')?.scrollIntoView({ block: 'nearest' });
        if (params.lang) {
            i18n.changeLanguage(params.lang);
        } else {
            i18n.changeLanguage(defaultLanguage);
        }
        const getFustDetail = async () => {
            try {
                setError(false);
                const fustService = new FustService();
                if (params.fustId) {
                    const value = await reCaptchaRef.current.executeAsync()
                    const fustDetail = await fustService.get(params.fustId, value)
                    if (params.lang && params.lang != defaultLanguage) {
                        const translatedFustDetail = await getTranslatedFustDescription(fustDetail);
                        setFustdetail(translatedFustDetail);
                    } else {
                        setFustdetail(fustDetail);
                    }
                } else {
                    setError(true);
                }
            } catch {
                setError(true);
            }
        };
        const getTranslatedFustDescription = async (fustDetail: FustDetailModel): Promise<FustDetailModel> => {
            try {
                const languageService = new LanguageService();
                if (params.lang) {
                    const value = await reCaptchaRef.current.executeAsync()
                    const translatedFustList = await languageService.get(params.lang, value);
                    var translatedDescription = translatedFustList.find(x => x.FustCode === fustDetail.FustCode)?.Omschrijving;
                    fustDetail.FustOmschrijving = translatedDescription ? translatedDescription : fustDetail.FustOmschrijving;
                    return fustDetail;
                }
                return fustDetail;
            } catch {
                return fustDetail;
            }
        };
        getFustDetail();
    }, []);

    const getUsageLabel = (usage: string) => {
        switch (usage) {
            case 'Eenmalig':
                return t('useDataSingleUse');
            case 'Meermalig':
                return t('useDataMultipleUse');
            case 'Beiden':
                return t('useDataBoth');
            default:
                return '';
        }
    };

    const AlternativeContent = () => {
        if (error) {
            return (
                <EmptyState
                    graphic={<EmptyStateIcon />}
                    header={t('errorHeader')}
                    subheader={t('errorBody')} />
            );
        } else {
            return (
                <LoadingIconText
                    loadingText={t('loadingText')}
                    positionCenter={false} />
            );
        }
    }

    return (
        <>
            <ReCAPTCHAComponent reCaptchaRef={reCaptchaRef} />
            <RfhTheme>
                <div id='topofpage' style={{ overflow: 'hidden' }}>
                    <RfhButton
                        onClick={() => { navigate(`/fustlijst/${params.lang}`); }}
                        variant="block--contained">
                        {t('backButton')}
                    </RfhButton>
                    <RfhTypography variant='h2'>
                        {t('headerText')}
                    </RfhTypography>
                    {fustdetail ?
                        <>
                            <div>
                                <br />
                                {fustdetail?.Fustafbeelding ?
                                    <img src={`data:image/jpeg;base64,${fustdetail.Fustafbeelding}`} style={{ maxWidth: '600px', maxHeight: '800px' }} /> :
                                    <div style={{ maxWidth: '600px', maxHeight: '800px' }}>
                                        <FallbackVisual
                                            backgroundColor='white'
                                            fontSize='16rem'
                                            fallbackText={t('placeholderImageText')}
                                        />
                                    </div>}
                                <br />
                                <br />
                            </div>
                            <div>
                                <table style={{ overflowX: 'auto' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '50%' }}><RfhTypography variant={tableHeaderVariant}>{t('generalTable.header')}</RfhTypography></th>
                                            <th style={{ width: '50%' }}></th>
                                        </tr>
                                        <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('generalTable.code')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.FustCode}</RfhTypography></td>
                                        </tr>
                                        <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('generalTable.description')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.FustOmschrijving}</RfhTypography></td>
                                        </tr>
                                        {!(fustdetail.Koppelcode === '') && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('generalTable.parts')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.Koppelcode}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.DeelCodes === '') && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('generalTable.partsOfCode')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.DeelCodes}</RfhTypography></td>
                                        </tr>}
                                        <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('generalTable.use')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{getUsageLabel(fustdetail.Duur)}</RfhTypography></td>
                                        </tr>
                                        <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('generalTable.type')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.Soort}</RfhTypography></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ overflowX: 'auto' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '50%' }}><RfhTypography variant={tableHeaderVariant}>{t('specificationTable.header')}</RfhTypography></th>
                                            <th style={{ width: '50%' }}></th>
                                        </tr>
                                        {!(fustdetail.Lengte === 0 || fustdetail.Lengte === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.length')} (mm)</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.Lengte}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.Breedte === 0 || fustdetail.Breedte === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.width')} (mm)</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.Breedte}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.Hoogte === 0 || fustdetail.Hoogte === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.height')} (mm)</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.Hoogte}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.KartonGewicht === null || fustdetail.KartonGewicht === 0) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.material')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.Materiaal}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.KunststofGewicht === null || fustdetail.KunststofGewicht === 0) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.plasticWeight')} (gram)</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.KunststofGewicht}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.KartonGewicht === null || fustdetail.KartonGewicht === 0) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.cartonWeight')} (gram)</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.KartonGewicht}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.MaxGewichtInhoud === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.maxWeight')} (gram)</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.MaxGewichtInhoud}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.MaxStapelhoogte === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.MaxPile')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.MaxStapelhoogte}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.AantalGaten === '') && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.amountOfHoles')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.AantalGaten}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.MinPotmaat === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.potSizeMin')} (mm)</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.MinPotmaat}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.MaxPotmaat === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('specificationTable.potSizeMax')} (mm)</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.MaxPotmaat}</RfhTypography></td>
                                        </tr>}
                                    </tbody>
                                </table>
                                <table style={{ overflowX: 'auto' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '50%' }}><RfhTypography variant={tableHeaderVariant}>{t('logisticalInfoTable.header')}</RfhTypography></th>
                                            <th style={{ width: '50%' }}></th>
                                        </tr>
                                        {!(fustdetail.UitgiftePak === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.numberOfPacksOut')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.UitgiftePak}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.UitgiftePallet === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.numberOfPalletsOut')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.UitgiftePallet}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.UitgifteKar === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.numberOfStwOut')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.UitgifteKar}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.AanvoerBledStw === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.numberOfStwSupply')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.AanvoerBledStw}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.AanvoerBledDcc === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.numberOfCcSupply')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.AanvoerBledDcc}</RfhTypography></td>
                                        </tr>}
                                        {!(fustdetail.InnameKar === null) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.numberOfStwIn')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.InnameKar}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.Vestigingen === null || fustdetail.Vestigingen == "") ?
                                            <tr className={styles.dataRow}>
                                                <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.issueLocation')}</RfhTypography></td>
                                                <td><RfhTypography variant={tableDataVariant}>{t('logisticalInfoTable.notDeliverable')}</RfhTypography></td>
                                            </tr>
                                            : <tr className={styles.dataRow}>
                                                <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.issueLocation')}</RfhTypography></td>
                                                <td><RfhTypography variant={tableDataVariant}>{fustdetail.Vestigingen}</RfhTypography></td>
                                            </tr>}
                                        {(fustdetail.LeveringOpTuinIndicatie) && <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('logisticalInfoTable.onPremiseDeliveryAmount')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.MinimumPalletAantal}</RfhTypography></td>
                                        </tr>}
                                    </tbody>
                                </table>
                                <table style={{ overflowX: 'auto' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '50%' }}><RfhTypography variant={tableHeaderVariant}>{t('priceInfoTable.header')}</RfhTypography></th>
                                            <th style={{ width: '50%' }}></th>
                                        </tr>
                                        {(fustdetail.Statiegeld === null || fustdetail.Statiegeld == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.deposit')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.Statiegeld}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.HuurUitgifte === null || fustdetail.HuurUitgifte == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.rentOutSupply')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.HuurUitgifte}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.HuurRetour === null || fustdetail.HuurRetour == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.rentRetSupply')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.HuurRetour}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.HuurAanvoerder === null || fustdetail.HuurAanvoerder == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.rentSubSupply')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.HuurAanvoerder}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.HuurKoper === null || fustdetail.HuurKoper == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.rentCustomer')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.HuurKoper}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.PrijsUitgifte === null || fustdetail.PrijsUitgifte == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.issuePriceSupply')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.PrijsUitgifte}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.VerrekenprijsAanvoer === null || fustdetail.VerrekenprijsAanvoer == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.transferPriceSupply')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.VerrekenprijsAanvoer}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.VerrekenprijsKoper === null || fustdetail.VerrekenprijsKoper == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.transferPriceCustomer')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.VerrekenprijsKoper}</RfhTypography></td>
                                        </tr>}
                                        {(fustdetail.KickBackKorting === null || fustdetail.KickBackKorting == "") ? null : <tr className={styles.dataRow}>
                                            <td><RfhTypography variant={tableDataLabelVariant}>{t('priceInfoTable.kickbackDiscount')}</RfhTypography></td>
                                            <td><RfhTypography variant={tableDataVariant}>{fustdetail.KickBackKorting}</RfhTypography></td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </> : <AlternativeContent />
                    }
                </div>
            </RfhTheme >
        </>
    )
}