import axios, { AxiosError } from 'axios';

import { LanguageModel } from './../models';
import { languageEndpoint } from './api-endpoints';

interface ILanguageService {
    get(taal: string, token: string): Promise<LanguageModel[]>;
}
export class LanguageService implements ILanguageService {
    async get(taalCode: string, token: string): Promise<LanguageModel[]> {
        try {
            var langList: LanguageModel[] = [];
            const response = await axios.get(`${languageEndpoint}/${taalCode}/${token}`);
            for (const fustCode of Object.keys(response.data)) {
                const description = response.data[fustCode];
                var fustLang = { FustCode: parseInt(fustCode), Omschrijving: description } as LanguageModel
                langList.push(fustLang);
            }
            return langList;
        } catch (ex) {
            const error = ex as AxiosError;
            console.log(error.message);
            throw error;
        }
    }
}